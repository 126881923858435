import '../scss/footer.scss';
import { Link } from 'react-router-dom';
import ScrollToTop from "react-scroll-to-top";
import SocialButton from "../components/SocialButton";

function FooterPage() {
  return (
    <div className="footer">
    <section>
    <div className="container">
      <div className="row">
      <div className="col-lg-3 col-md-6 globeXImage" style={{marginTop:'10px'}}>
      <img loading="lazy" src="./assets/globe_footer.gif" className='globeImg' alt="service-icon" style={{width:'230px'}} />
      </div>
        <div className="col-lg-3 col-md-6" style={{marginTop:'10px'}}>
          <h4><b><span className="fLogoTitle">Rameswaram</span> </b> <br/>
          <span className="fLogoSubtitle">BSS Tours & Travels </span></h4>
          <p className="fSubText">
            Rameswaram, Ramanathapuram, Tamilnadu, India.<br/>
            Phone: +91 8148305945 <br/>
            Email: info@rameswarambss.com
          </p>
         
        </div>
        <div className="col-lg-3 col-md-6" style={{marginTop:'10px'}}>
        <h3 className="fLogoTitle">Quick Links</h3>
          <div className="fSubText">
            <Link to='/about' className='fSubTextLink'>About</Link> 
            <br/> 
            <Link to='/products' className='fSubTextLink'>Packages</Link>
            <br/> 
            <Link to='/services' className='fSubTextLink'>Hotels</Link>
            <br/> 
            <Link to='/places' className='fSubTextLink'>Places</Link>
            <br/> 
            <Link to='/contact' className='fSubTextLink'>Contact Us</Link> 
            <br/> 
            <Link to='/privacy' className='fSubTextLink'>Privacy Policy</Link>  
            <br/> 
            <Link to='/termcondition' className='fSubTextLink'>Terms and Condition</Link> 
          </div>
        </div>
        <div className="col-lg-3 col-md-6" style={{marginTop:'10px'}}>
          <h3 className="fLogoTitle">Operating Hours</h3>
          <div className="fSubText">
            <table>
              <thead></thead>
              <tbody>
              <tr>
               <td>24X7 </td>
              </tr> 
              <tr>
               <td></td>
              </tr>                   
              </tbody>
              <tfoot>
              <h4><span className="fLogoSubtitle">Follow us on </span></h4>
                <SocialButton/>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>
    </section>

  <section className="copyright" style={{backgroundColor : '#fff' }}>
    <div className="container footerText">
      <div className="row align-items-center">
        <div className="col-md-12">
          <div className="copyright-info text-center text-md-center">
            <span>Copyright &copy; 
               ratchu{ new Date().getFullYear() }
              ,  <img src="./assets/sign.png" alt="Ratchus" className='myIcon'/>  Powered by Ratchus</span>
          </div>
        </div>
      </div>
    </div>
  </section>
  <ScrollToTop smooth style={{backgroundColor:'lightblue', color:'blue'}}/>
  </div>
  )
}

export default FooterPage