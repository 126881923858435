import React, {  useState } from "react";
import styled from "styled-components";
import { imageZoomEffect, TitleStyles } from "../services/ReusableStyle";
import { Image } from 'antd';
import { Link } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Slider from 'react-slick';
import { myImageDetails } from '../services/impImages';

function GalleryPage() {

   // let navigate=useNavigate();
   // const handleClick = (e) => navigate('/press');
    const [visible, setVisible] = useState(false);
    const [imgSrc, setImage] = useState(null);
    const handleImgView = (img) => {
      setVisible(true);
      setImage(img);
    }
  
    const slides=[
        {url:'assets/slider-main/bg8.jpg', head:'',title:'',subTitle:''},
      ];
      let mainSettings = {
        infinite: true,
        autoplay:true,
        speed: 1000,
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1,
    
        responsive: [
          {
            breakpoint: 960,
            mainSettings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              arrows: false,
            },
          },
          {
            breakpoint: 480,
            mainSettings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              arrows: false,
            },
          },
        ],
      };
  return (
    <div className="body-inner" id="services">
      <section className="banner-carousel banner-carousel-1 mb-0">
      <div className='serviceHomeText'>
                <h1>Gallery</h1>
                <Link to='/' className='homeTextLink'>Home</Link> | Gallery
      </div>
        <ToastContainer style={{width:'100%'}}/>
<div className='mainContainerStyles'>
<Slider {...mainSettings}>
        {slides.map((image) => (
                        <img loading="lazy" className='serviceImgContainer' src={image.url} alt='Ratchu Software' />
                ))}
        </Slider>
</div>
</section>
<Section>
    <div className="title">
            <h1>
                <span>Our Gallery</span>
            </h1>
        </div>
        <div className="press">
        {myImageDetails.map((press) => {
            return (
                <div className="pressDetails" key={press.id}>
                    <div className="image">
                        <img src={press.img} alt="" onClick={() => handleImgView(`${press.img}`)} className='btn'/>
                        <Image
                    width="100%"
                    height="100%"
                    style={{
                      display: 'none',
                    }}
                    preview={{
                      visible,
                      src: imgSrc,
                      onVisibleChange: (value) => {
                        setVisible(value);
                      },
                    }}
                  />  
                    </div>
                </div>
            );
        })}
        </div>
    </Section>
    
<section className="infoContainer" style={{background:"rgb(230, 105, 3)"}}>
  <div className="container">
    <div className="row">
        <div className="col-md-12">
          <div className="subscribe-call-to-acton">
              <h1 className='callUs'>Call Us +91 8148305945</h1>
          </div>
        </div>
    </div>
  </div>
</section>
    </div>
  )
}

export default GalleryPage;


const Section = styled.section`
  ${TitleStyles};
  ${imageZoomEffect};

     display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 3rem;
    padding-top: 1rem;
  .press {
    margin-top: 3rem;
    display: grid;
    grid-template-columns:  repeat(3, 1fr);
    gap: 3rem;
    justify-content: left;
    align-items: left;
    text-align:left;

   .pressDetails{
    justify-content: left;
     align-items: left;
     text-align:left;
    
    .image {
        max-height: 30rem;
        overflow: hidden;
        border-radius: 1rem;
        max-width: 99%;
        img {
            width: 99%;
          object-fit: cover;
        }
    }
    h4{
        text-align:center;
        font-size: 1.5375rem;
        letter-spacing: 0.01rem;
    }
    p{
      padding: 0.5rem 2rem;
        font-family: 'Arimo', sans-serif;
        font-size: 1.0625rem;
        letter-spacing: 0.1rem;
      line-height: 1rem;
    }

    button {
      border: none;
      padding: 0.5rem 2rem;
      font-size: 1.0813rem;
      font-weight: 600;
      color: black;
      margin-top: 1rem;
      margin-bottom: 1rem;
      letter-spacing: 0.1rem;
      line-height: 1.6rem;
      border-radius: 2rem;
      transition: 0.5s ease-in-out;
      cursor: pointer;
      background:none;
      text-transform: uppercase;
   }
  }
}
  .btn {
    border: solid green;
    transition: 0.5s ease-in-out;
    cursor: pointer;
 }

  @media screen and (min-width: 280px) and (max-width: 720px) {
    margin: 0 5vw;
    .press {
      grid-template-columns: repeat(1, 1fr);
    }
  }
  @media screen and (min-width: 720px) and (max-width: 1080px) {
    margin: 0 10vw;
    .press{
    grid-template-columns:  repeat(2, 2fr);
    }
   }
  `;